<template>
    <v-sheet class="pa-4 pb-4 text-center outlet-form sheet-form" color="light-blue lighten-4" elevation="4">
        <h3 class="grey--text text--darken-4 mb-4 mb-5">Update Password</h3>
        <v-form
                ref="formPassword"
                v-model="credentials.valid"
                lazy-validation
                class="mb-4 wallet-form"
                @submit="submit()"
        >
            <v-text-field
                    solo
                    dense
                    light
                    v-model="credentials.current_password"
                    label="Current Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    :loading="busy"
                    :rules="[rules.required]"
                    :error-messages="error && error.current_password ? error.current_password[0] : ''"
                    required
            ></v-text-field>
            <v-text-field
                    solo
                    dense
                    light
                    v-model="credentials.password"
                    label="New Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    :loading="busy"
                    :rules="[rules.required, rules.password]"
                    :error-messages="error && error.password ? error.password[0] : ''"
                    required
            ></v-text-field>
            <v-text-field
                    solo
                    dense
                    light
                    v-model="credentials.password_confirmation"
                    label="Repeat New Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    :loading="busy"
                    :rules="[rules.required, rules.password, passwordConfirmationRule]"
                    :error-messages="error && error.password_confirmation ? error.password_confirmation[0] : ''"
                    @keypress.enter="submit()"
                    required
            ></v-text-field>
        </v-form>
        <v-btn block color="light-blue darken-3" :loading="busy" @click="submit('password')">SAVE</v-btn>
    </v-sheet>
</template>

<script>
    import data from './../mixins/data'
    import rules from './../mixins/rules'

    export default {
        mixins: [data, rules],
        data: () => ({
            busy: false,
            error: null,
            credentials: {
                current_password: null,
                password: null,
                password_confirmation: null,
                valid: true
            },
            show1: false,
            show2: false,
            show3: false,
        }),
        computed: {
            passwordConfirmationRule() {
                return (
                    this.credentials.password === this.credentials.password_confirmation ||
                    "Password must match"
                );
            }
        },
        methods: {
            submit() {
                if (this.$refs.formPassword.validate()) {
                    this.postData(`/reseller/account/password`, this.credentials, () => {
                        this.$refs.formPassword.reset()
                        this.$store.dispatch("prompt", { message: 'Password updated!' });
                    })
                }
            }
        }
    }
</script>
